<template>
  <div>
    <v-app-bar
      :height="$vuetify.breakpoint.xs && page === 'provider' ? 70 : 88"
      :flat="!$vuetify.breakpoint.xs || page === 'register'"
      :color="page === 'provider' ? 'primary' : 'transparent'"
      class="px-2 px-sm-5"
    >
      <v-app-bar-nav-icon
        v-if="page === 'provider'"
        dark
        :ripple="false"
        class="hidden-lg-and-up"
        @click="drawer = true"
      />
      <v-btn
        depressed
        plain
        dark
        :ripple="false"
        :to="{
          name: page === 'register' ? 'UserMainPage' : 'ProviderHomepage',
        }"
        class="v-btn--active pl-0"
        :class="page === 'provider' ? 'hidden-md-and-down' : 'mt-4'"
      >
        <v-img src="@/assets/icons/LogoZaturnaHorizontal.svg" />
      </v-btn>
      <v-toolbar-items
        v-if="page === 'provider'"
        class="hidden-md-and-down align-center"
      >
        <v-btn
          v-for="(section, i) in desktopNavbar"
          :key="i"
          depressed
          text
          dark
          :ripple="false"
          class="text-caption btn-background-transparent v-btn--active"
          exact-active-class="navbar-active font-weight-bold"
          router
          :exact="section.title === 'Inicio'"
          :to="{ name: section.routerName }"
        >
          <span class="navbar-title">{{ section.title }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items v-if="page === 'provider'" style="overflow-x: hidden">
        <v-menu
          offset-y
          offset-x
          :open-on-hover="!$vuetify.breakpoint.xs"
          rounded="md"
          transition="slide-y-transition"
          max-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              class="d-flex align-center my-2 my-sm-4"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                v-if="provider.photoUrl"
                :aspect-ratio="1"
                :src="provider.photoUrl"
                width="34"
                class="rounded-circle"
              />
              <v-icon v-else size="30" color="white">fa-user-circle</v-icon>
              <span
                v-if="provider.name"
                class="hidden-xs-only white--text ml-3"
              >
                {{ provider.name }}
              </span>
            </a>
          </template>
          <v-card tile min-width="180px">
            <v-list dense class="py-0">
              <v-list-item
                v-for="(item, i) in menu"
                :key="i"
                :class="i == 0 ? 'pt-1' : i == menu.length - 1 && 'pb-1'"
                @click="handleMenuRedirect(item.routeName)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-subtitle-1"
                    :class="{
                      'font-weight-black': item.bold,
                    }"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-if="page === 'provider'"
      v-model="drawer"
      fixed
      temporary
      :ripple="false"
    >
      <v-btn
        :height="$vuetify.breakpoint.xs ? 70 : 88"
        depressed
        plain
        block
        :ripple="false"
        class="primary rounded-0 v-btn--active"
        :to="{ name: 'ProviderHomepage' }"
        @click="drawer = false"
      >
        <v-img
          :height="$vuetify.breakpoint.xs ? 45 : 53"
          contain
          src="@/assets/icons/LogoZaturnaHorizontal.svg"
        />
      </v-btn>
      <v-list class="py-0">
        <v-list-item-group v-model="group" active-class="secondary--text">
          <v-list-item
            v-for="(item, i) in mobileNavbar"
            :key="i"
            router
            :to="{ name: item.routerName }"
            :exact="item.title === 'Inicio'"
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Auth } from "aws-amplify";

export default {
  props: {
    page: { type: String, default: "provider" },
  },
  data: () => ({
    drawer: false,
    group: null,
    mobileNavbar: [
      {
        title: "Inicio",
        routerName: "ProviderHomepage",
      },
      {
        title: "Mis productos",
        routerName: "ProviderProducts",
      },
      {
        title: "Solicitudes",
        routerName: "ProviderRequests",
      },
      {
        title: "Reservaciones",
        routerName: "ProviderReservations",
      },
      {
        title: "Calendario",
        routerName: "ProviderCalendar",
      },
      // {
      //   title: "Rendimiento",
      //   routerName: "ProviderPerformance",
      // },
    ],
    desktopNavbar: [
      {
        title: "Inicio",
        routerName: "ProviderHomepage",
      },
      {
        title: "Mis productos",
        routerName: "ProviderProducts",
      },
      {
        title: "Solicitudes",
        routerName: "ProviderRequests",
      },
      {
        title: "Reservaciones",
        routerName: "ProviderReservations",
      },
      {
        title: "Calendario",
        routerName: "ProviderCalendar",
      },
      // {
      //   title: "Rendimiento",
      //   routerName: "ProviderPerformance",
      // },
    ],
    menu: [
      {
        title: "Mi perfil",
        routeName: "ProviderProfile",
        bold: true,
      },
      {
        title: "Modo cliente",
        routeName: "UserMainPage",
      },
      {
        title: "Ayuda",
        routeName: "UserHelp",
      },
      {
        title: "Cerrar sesión",
        routeName: "SignOut",
      },
    ],
  }),
  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      } else {
        return { photo: null };
      }
    },
  }),
  methods: {
    handleMenuRedirect(route) {
      if (route == "SignOut") {
        this.signOut();
      } else {
        this.$router
          .push({
            name: route,
          })
          .catch(() => {});
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
        await this.$store.dispatch("signedIn", false);
        await this.$store.dispatch("saveCognitoUser", null);
        this.$router.push({ name: "MainPage" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 1.1rem !important;
  }
  &:hover {
    .navbar-title {
      transform: scale(1.01);
    }
  }
  &.navbar-active .navbar-title {
    transform: scale(1.01);
  }
}

.btn-cristal {
  background: rgba(255, 255, 255, 0.3) !important;
}

.slide-y-transition-leave-to {
  transform: none;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
