var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"px-2 px-sm-5",attrs:{"height":_vm.$vuetify.breakpoint.xs && _vm.page === 'provider' ? 70 : 88,"flat":!_vm.$vuetify.breakpoint.xs || _vm.page === 'register',"color":_vm.page === 'provider' ? 'primary' : 'transparent'}},[(_vm.page === 'provider')?_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",attrs:{"dark":"","ripple":false},on:{"click":function($event){_vm.drawer = true}}}):_vm._e(),_c('v-btn',{staticClass:"v-btn--active pl-0",class:_vm.page === 'provider' ? 'hidden-md-and-down' : 'mt-4',attrs:{"depressed":"","plain":"","dark":"","ripple":false,"to":{
        name: _vm.page === 'register' ? 'UserMainPage' : 'ProviderHomepage',
      }}},[_c('v-img',{attrs:{"src":require("@/assets/icons/LogoZaturnaHorizontal.svg")}})],1),(_vm.page === 'provider')?_c('v-toolbar-items',{staticClass:"hidden-md-and-down align-center"},_vm._l((_vm.desktopNavbar),function(section,i){return _c('v-btn',{key:i,staticClass:"text-caption btn-background-transparent v-btn--active",attrs:{"depressed":"","text":"","dark":"","ripple":false,"exact-active-class":"navbar-active font-weight-bold","router":"","exact":section.title === 'Inicio',"to":{ name: section.routerName }}},[_c('span',{staticClass:"navbar-title"},[_vm._v(_vm._s(section.title))])])}),1):_vm._e(),_c('v-spacer'),(_vm.page === 'provider')?_c('v-toolbar-items',{staticStyle:{"overflow-x":"hidden"}},[_c('v-menu',{attrs:{"offset-y":"","offset-x":"","open-on-hover":!_vm.$vuetify.breakpoint.xs,"rounded":"md","transition":"slide-y-transition","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-flex align-center my-2 my-sm-4"},'a',attrs,false),on),[(_vm.provider.photoUrl)?_c('v-img',{staticClass:"rounded-circle",attrs:{"aspect-ratio":1,"src":_vm.provider.photoUrl,"width":"34"}}):_c('v-icon',{attrs:{"size":"30","color":"white"}},[_vm._v("fa-user-circle")]),(_vm.provider.name)?_c('span',{staticClass:"hidden-xs-only white--text ml-3"},[_vm._v(" "+_vm._s(_vm.provider.name)+" ")]):_vm._e()],1)]}}],null,false,3373790511)},[_c('v-card',{attrs:{"tile":"","min-width":"180px"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,class:i == 0 ? 'pt-1' : i == _vm.menu.length - 1 && 'pb-1',on:{"click":function($event){return _vm.handleMenuRedirect(item.routeName)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-1",class:{
                    'font-weight-black': item.bold,
                  },domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1):_vm._e()],1),(_vm.page === 'provider')?_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","ripple":false},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-btn',{staticClass:"primary rounded-0 v-btn--active",attrs:{"height":_vm.$vuetify.breakpoint.xs ? 70 : 88,"depressed":"","plain":"","block":"","ripple":false,"to":{ name: 'ProviderHomepage' }},on:{"click":function($event){_vm.drawer = false}}},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 45 : 53,"contain":"","src":require("@/assets/icons/LogoZaturnaHorizontal.svg")}})],1),_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',{attrs:{"active-class":"secondary--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.mobileNavbar),function(item,i){return _c('v-list-item',{key:i,attrs:{"router":"","to":{ name: item.routerName },"exact":item.title === 'Inicio'}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }