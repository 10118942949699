var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"filter-result":_vm.filterResult,"items":_vm.filterStatusReservations,"item-key":"uniqueId","header-props":{
      'sort-by-text': 'Ordenar por',
    },"loading":_vm.loading,"loading-text":"Cargando tabla...","no-results-text":"No se han encontrado reservaciones","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
      prevIcon: 'fa-angle-left secondary--text',
      nextIcon: 'fa-angle-right secondary--text',
    }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.dateFormatter(item.date))+" ")])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticClass:"black--text text-decoration-underline font-weight-bold",attrs:{"to":_vm.getRoute(item.id, item.productType)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"ma-0 mt-2 mb-4 ma-sm-2"},[_c('Button',{attrs:{"text":"Ver detalles","block":"","outlined":"","aria-label":"Ir a detalles de la reservación","url":_vm.getRoute(item.id, item.productType).name,"url-params":_vm.getRoute(item.id, item.productType).params}})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay reservaciones registradas ")]},proxy:true}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","height":"3px","color":"secondary","indeterminate":""},slot:"progress"})],1),(_vm.paginationStatus)?_c('div',[_c('div',{staticClass:"text-center pt-6"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","color":"secondary"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }