<template>
  <v-container fluid ma-0 pa-0>
    <Navbar page="provider" />
    <v-container class="body-container px-6 px-md-auto">
      <div class="text-h1 font-weight-bold mt-16 mb-11 pt-md-3">
        Reservaciones
      </div>
      <v-card class="elevation-0">
        <v-tabs
          v-model="filterResult"
          color="secondary"
          background-color="transparent"
          show-arrows
          slider-size="5"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item, i) in buttonTabFilter"
            :key="i"
            class="text-h3 btn-background-transparent text-capitalize black--text px-3 px-sm-6"
            :ripple="false"
            :active-class="'font-weight-bold'"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card>
      <v-divider color="secondary" />
      <v-row no-gutters class="mt-8">
        <v-col cols="12" md="8" class="mb-4 mb-md-0">
          <v-row no-gutters>
            <v-col cols="auto" sm="auto" class="mr-2 mb-3 mb-md-0">
              <Button
                text="Todos"
                outlined
                pill
                block
                :active="activeFilter === 'products'"
                aria-label="Mostrar todas las reservaciones"
                @event="handleActiveFilter('products')"
              />
            </v-col>
            <v-col cols="auto" sm="auto" class="mr-sm-2 mb-3 mb-md-0">
              <Button
                text="Por servicio"
                outlined
                pill
                block
                :active="activeFilter === 'services'"
                aria-label="Mostrar reservaciones filtradas por servicio"
                @event="handleActiveFilter('services')"
              />
            </v-col>
            <v-col cols="auto" sm="auto" class="mr-sm-2 mb-3 mb-md-0">
              <Button
                text="Por paquete"
                outlined
                pill
                block
                :active="activeFilter === 'packages'"
                aria-label="Mostrar reservaciones filtradas por paquete"
                @event="handleActiveFilter('packages')"
              />
            </v-col>
            <!-- <v-col cols="12" sm="auto">
              <v-select
                v-model="selectedSearch"
                :items="searchSelect"
                item-text="state"
                placeholder="Ordenar por"
                :label="selectedSearch ? 'Ordernar por' : null"
                item-value="abbr"
                class="custom-select-input v-input--is-focused rounded-pill text-h4 secondary--text mb-1 mb-md-0"
                color="secondary"
                outlined
                dense
                hide-details
                height="50"
              />
            </v-col>-->
          </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="6" md="4" class="d-flex justify-end">
          <v-card
            height="64"
            flat
            width="100%"
            class="card-outlined rounded-lg secondary--text d-flex px-3 pt-1"
          >
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                >
                  Nombre del cliente
                </v-list-item-title>
                <v-hover v-slot="{ hover }">
                  <v-text-field
                    v-model="nameFilter"
                    dense
                    flat
                    hide-details
                    color="secondary"
                    :clearable="!$vuetify.breakpoint.xs"
                    :clear-icon="'mdi-close secondary--text'"
                    class="custom-text-field pa-0 ma-0"
                    :class="hover || nameFilter ? 'v-input--is-focused' : ''"
                    placeholder="¿Cuál es el nombre del cliente?"
                  ></v-text-field>
                </v-hover>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-11">
          <ReservationTable
            :name-filter="nameFilter"
            :filter-result="filterResult"
            :filter-status-reservations="filterStatusReservations"
            :loading="loading"
          />
        </v-col>
        <!-- <v-col align="center">
          <p>
            Como podemos hacer que sea mas facil administrar tus
            reservaciones.
            <v-btn text class="ma-0 pa-0"
              ><b>Comparte tus comentarios</b></v-btn
            >
          </p>
        </v-col> -->
      </v-row>
    </v-container>
    <Footer class="mt-16" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import ReservationTable from "@/components/Provider/Reservation/ReservationTable.vue";
import Footer from "@/components/Shared/Footer.vue";
import { mergeProductsLists } from "@/Utils/Utils.js";
import { GET_PROVIDER_RESERVATIONS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";

export default {
  components: {
    Navbar,
    Button,
    ReservationTable,
    Footer,
  },
  data: (vm) => ({
    reservations: [],
    menu: false,
    select: null,
    date: null,
    buttonTabFilter: ["Todas", "Próximas", "Concluidas"],
    nameFilter: "",
    textInput: "",
    activeFilter: "products",
    filterResult: 0,
    loading: true,
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    searchSelect: ["Mas reciente", "Menos reciente"],
    selectedSearch: "",
  }),

  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    filterStatusReservations() {
      const filteredList = this.filterReservationsbyType();
      let eventValue = [];
      if (this.filterResult === 1) {
        eventValue = this.getFutureReservations(filteredList);
      } else if (this.filterResult === 2) {
        eventValue = this.getOldReservations(filteredList);
      } else {
        eventValue = filteredList;
      }
      return eventValue;
    },
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    provider: {
      async handler(provider) {
        if (!provider || !provider.id) return;
        this.fetchReservations(parseInt(provider.id));
      },
      immediate: true,
    },
  },

  methods: {
    async fetchReservations(providerId) {
      const { data, errors } = await useQuery(GET_PROVIDER_RESERVATIONS, {
        providerId,
      });
      if (data) {
        this.serviceReservations = this.formatServiceReservations(
          data.providerReservations ? data.providerReservations.service : []
        );
        this.packageReservations = this.formatPackageReservations(
          data.providerPackageReservations || []
        );
        this.reservations = mergeProductsLists(
          this.serviceReservations,
          this.packageReservations
        );
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },

    getFutureReservations(reservationsList) {
      return reservationsList.filter(function (item) {
        return item.status !== "completed";
      });
    },

    getOldReservations(reservationsList) {
      return reservationsList.filter(function (item) {
        return item.status === "completed";
      });
    },
    filterReservationsbyType() {
      switch (this.activeFilter) {
        case "products":
          return this.reservations;
        case "services":
          return this.serviceReservations;
        case "packages":
          return this.packageReservations;
        default:
          return this.reservations;
      }
    },
    formatServiceReservations(reservations) {
      let formattedList = [];
      reservations.map((item) => {
        item.serviceQuotationRequests.map((data) => {
          formattedList.push({
            name: data.requesterName,
            id: data.reservation !== null ? data.reservation.id : 0,
            phoneNumber: data.requesterPhone,
            productName: item.name,
            email: data.requesterEmail,
            date: data.eventDate,
            status: data.status,
            productType: "Servicio",
          });
        });
      });
      return formattedList;
    },
    formatPackageReservations(reservations) {
      const formattedList = reservations.map((reservation) => {
        return {
          name: reservation.userName,
          id: reservation.id,
          phoneNumber: reservation.userPhone,
          productName: reservation.packageName,
          email: reservation.userEmail,
          date: reservation.eventDate,
          status: reservation.status,
          productType: "Paquete",
        };
      });
      return formattedList;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    handleActiveFilter(filter) {
      this.activeFilter = filter;
    },
  },
};
</script>

<style scoped lang="scss">
.card-outlined {
  border: 1px solid var(--v-secondary-base);
}

::v-deep .custom-select-input input::placeholder {
  color: black !important;
  padding: 0 6px;
}

::v-deep .custom-select-input .v-input__append-inner {
  padding-top: 4px;
}

.custom-text-field.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding-left: 0;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
