<template>
  <div>
    <v-data-table
      :headers="headers"
      :filter-result="filterResult"
      :items="filterStatusReservations"
      item-key="uniqueId"
      :header-props="{
        'sort-by-text': 'Ordenar por',
      }"
      :loading="loading"
      loading-text="Cargando tabla..."
      no-results-text="No se han encontrado reservaciones"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :footer-props="{
        prevIcon: 'fa-angle-left secondary--text',
        nextIcon: 'fa-angle-right secondary--text',
      }"
      @page-count="pageCount = $event"
    >
      <v-progress-linear
        v-show="loading"
        slot="progress"
        height="3px"
        color="secondary"
        indeterminate
      ></v-progress-linear>

      <template v-slot:[`item.date`]="{ item }">
        <div>
          {{ dateFormatter(item.date) }}
        </div>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="black--text text-decoration-underline font-weight-bold"
          :to="getRoute(item.id, item.productType)"
        >
          {{ item.id }}
        </router-link>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="ma-0 mt-2 mb-4 ma-sm-2">
          <Button
            text="Ver detalles"
            block
            outlined
            aria-label="Ir a detalles de la reservación"
            :url="getRoute(item.id, item.productType).name"
            :url-params="getRoute(item.id, item.productType).params"
          />
          <!-- <ModalFeecback
            v-else-if="
              item.date <
              new Date(new Date().toISOString().split('T')[0]).getTime() / 1000
            "
            class="my-2"
            align="left"
          /> -->
        </div>
      </template>
      <template v-slot:no-data> No hay reservaciones registradas </template>
    </v-data-table>
    <div v-if="paginationStatus">
      <div class="text-center pt-6">
        <v-pagination
          v-model="page"
          :length="pageCount"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          color="secondary"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
//import ModalFeedback from "../../Provider/Reservation/ModalFeedback.vue";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter.js";

export default {
  components: {
    Button,
    //ModalFeedback
  },
  props: {
    nameFilter: {
      type: String,
      default: "",
    },
    filterResult: {
      type: Number,
      default: 0,
    },
    filterStatusReservations: {
      type: [String, Array],
      default: "",
    },
    loading: {
      type: Boolean,
      required: true,
    },
    paginationStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nombre",
          value: "name",
          filter: (f) => {
            if (this.nameFilter) {
              return f.toLowerCase().includes(this.nameFilter.toLowerCase());
            } else {
              return f;
            }
          },
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Producto",
          value: "productName",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Recibo",
          value: "id",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Teléfono",
          value: "phoneNumber",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Correo",
          value: "email",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Fecha del evento",
          value: "date",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: " ",
          align: "center",
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getRoute(reservationId, productType) {
      return {
        name:
          productType === "Servicio"
            ? "ProviderServiceReservation"
            : "ProviderPackageReservation",
        params: {
          id: reservationId,
        },
      };
    },

    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
  },
};
</script>

<style scoped>
.td-font {
  font-size: 1.05rem !important;
}
::v-deep .v-data-table-header tr {
  white-space: nowrap !important;
}
</style>
